<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <el-button type="primary" @click="singleDetection" :disabled="disabled" plain class="mgB10">单个检测</el-button>
      <el-button type="primary" @click="onKeyDetection" :disabled="disabled" plain class="mgL10 mgB10">一键检测</el-button>
      <el-button type="success" @click="add" plain class="mgL10 mgB10">添加</el-button>
      <el-row>
        <el-col :xs="24" :sm="15" :md="18" :lg="18" :xl="18">
          <el-table ref="table" :data="tableData" border stripe v-loading="loading" style="width: 100%;">
            <el-table-column width="55" label="-" header-align="center" align="center">
              <template slot-scope="scope">
                <el-radio v-model="testingId" :label="scope.row.id"></el-radio>
              </template>
            </el-table-column>
            <el-table-column type="index" header-align="center" align="center" label="序号" width="50">
            </el-table-column>
            <el-table-column prop="mainNo" :show-overflow-tooltip="true" header-align="center" align="center"
              label="分机号">
            </el-table-column>
            <el-table-column prop="luyouNo" :show-overflow-tooltip="true" header-align="center" align="center"
              label="路由号">
            </el-table-column>
            <el-table-column prop="luyouOne" :show-overflow-tooltip="true" header-align="center" align="center"
              label="路由1">
            </el-table-column>
            <el-table-column prop="luyouTwo" :show-overflow-tooltip="true" header-align="center" align="center"
              label="路由2">
            </el-table-column>
            <el-table-column prop="luyouThree" :show-overflow-tooltip="true" header-align="center" align="center"
              label="路由3">
            </el-table-column>
            <el-table-column prop="luyouFour" :show-overflow-tooltip="true" header-align="center" align="center"
              label="路由4">
            </el-table-column>
            <el-table-column prop="luyouFive" :show-overflow-tooltip="true" header-align="center" align="center"
              label="路由5"></el-table-column>
            <el-table-column prop="fenjiSize" :show-overflow-tooltip="true" header-align="center" align="center"
              label="分机容量">
              <template slot-scope="scope">
                {{scope.row.fenjiSize|capacityFilters}}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120" header-align="center" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button @click.native.prevent="editRow(scope.row)" size="mini" class="custom-table-btn">
                  编辑
                </el-button>
                <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini" class="custom-table-btn">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :xs="24" :sm="9" :md="6" :lg="6" :xl="6" class="pdL10">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span class="border-l relative pdL10">检测信息</span>
            </div>
            <div style="height: calc(100vh - 250px);">
              <testing-text :list="socketText"></testing-text>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <!-- 编辑  添加-->
    <el-dialog class="custom-dialog" :title="formType?'编辑':'添加'" :visible.sync="dialogVisible" width="520px"
      :append-to-body="true" @close="closeAlert">
      <el-form ref="form" :rules="formRules" :model="form" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="分机号" prop="mainNo" class="custom-form-item">
              <el-select v-model="form.mainNo" style="width: 100%;" placeholder="请选择分机号">
                <el-option v-for="(item,index) in extensionList" :key="index" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="分机容量" prop="fenjiSize" class="custom-form-item">
              <el-select v-model="form.fenjiSize" placeholder="请选择分机容量" style="width: 100%;">
                <el-option v-for="(item,index) in capacityList" :key="index" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路由1" prop="luyouOne" class="custom-form-item">
              <el-input v-model="form.luyouOne" placeholder="请输入路由1"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="路由2" prop="luyouTwo" class="custom-form-item">
              <el-input v-model="form.luyouTwo" placeholder="请输入路由2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路由3" prop="luyouThree" class="custom-form-item">
              <el-input v-model="form.luyouThree" placeholder="请输入路由"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="路由4" prop="luyouFour" class="custom-form-item">
              <el-input v-model="form.luyouFour" placeholder="请输入路由4"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路由5" prop="luyouFive" class="custom-form-item">
              <el-input v-model="form.luyouFive" placeholder="请输入路由5"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
        </el-button>
        <el-button @click="closeAlert">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  const capacity = [{
    name: "512",
    value: "00"
  }, {
    name: "768",
    value: "07"
  }, {
    name: "1024",
    value: "10"
  }]
  export default {
    components: {
      "testing-text": () => import('@/components/TestingText/Index')
    },
    filters: {
      capacityFilters(e) {
        let current = capacity.findIndex(item => item.value === e)
        if (current !== -1) {
          return capacity[current].name
        }
        return e;
      }
    },
    data() {
      return {
        socketText: [],
        testingId: '',
        capacityList: capacity,
        tableData: [],
        extensionList: [],
        loading: false,
        dialogVisible: false,
        formType: false,
        form: {
          id: "",
          mainNo: "",
          luyouNo: "",
          luyouOne: "ffff",
          luyouTwo: "ffff",
          luyouThree: "ffff",
          luyouFour: "ffff",
          luyouFive: "ffff",
          fenjiSize: ""
        },
        loadingAlert: false,
        formRules: {
          mainNo: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          luyouNo: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          luyouOne: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          luyouTwo: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          luyouThree: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          luyouFour: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          luyouFive: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          fenjiSize: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }]
        },
        disabled: false
      }
    },
    mounted() {
      this.getData()
      this.getMains();
    },
    beforeRouteEnter(to, from, next) {
      console.log(to, from)
      next(vm => {
        vm.addSocket()
      })
    },
    beforeRouteLeave(to, from, next) {
      console.log(to, from)
      if (window.ws) window.ws.onmessage = null;
      next()
    },
    methods: {
      addSocket() {
        var than = this;
        var timer = setInterval(() => {
          if (window.ws) {
            window.ws.onmessage = function(e) {
              let data = JSON.parse(e.data);
              if (data.testingType === 1) {
                than.socketText.push(data)
                if (data.type === 7 || data.type === 4) {
                  than.disabled = false;
                }
              }
              console.log(e)
            }
            clearInterval(timer)
          }
        }, 1000)
      },
      singleDetection() {
        if (!window.ws) {
          this.$toast({
            message: "Socket未连接",
            position: 'bottom',
          });
          return;
        }
        let current = this.tableData.findIndex(item => item.id === this.testingId)
        if (current != -1) {
          this.socketText = [];
          this.disabled = true;
          this.$axios.Post(this.$api.testingLuyou, {
            "mainNo": this.tableData[current].mainNo,
            "luyouNo": this.tableData[current].luyouNo
          }).then(() => {}).catch((err) => {
            this.disabled = false;
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        } else {
          this.$message({
            type: 'warning',
            message: "请选择正确的路由"
          });
        }
      },
      onKeyDetection() {
        if (!window.ws) {
          this.$message({
            type: 'error',
            message: "Socket未连接"
          });
          return;
        }
        this.socketText = [];
        this.disabled = true;
        this.$axios.Post(this.$api.testingLuyouAll, {}).then(() => {}).catch((err) => {
          this.disabled = false;
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
        })
      },
      //获取数据
      getData() {
        this.loading = true;
        this.$axios.Get(this.$api.cfExtensionList, {}).then(res => {
          this.tableData = res.data;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      //获取分机号
      getMains() {
        this.$axios.Get(this.$api.cfAbarnGetMains, {}).then(res => {
          this.extensionList = res.data;
        })
      },
      //添加
      add() {
        this.dialogVisible = true;
      },
      editRow(row) {
        this.form = JSON.parse(JSON.stringify(row))
        this.formType = true;
        this.dialogVisible = true;
      },
      //删除
      delRow(e) {
        this.$confirm('此操作将永久删除该数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let than = this;
          this.$axios.Post(this.$api.cfExtensionDel, {
            "mainNo": e.mainNo,
            "luyouNo": e.luyouNo
          }).then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            than.getData()
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      },
      //提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.formType ? this.editUser() : this.addUser()
          } else {
            return false;
          }
        });
      },
      //添加信
      addUser() {
        this.loadingAlert = true
        this.$axios.Post(this.$api.cfExtensionAdd, this.form).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.closeAlert();
          this.getData()
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //编辑信息
      editUser() {
        this.loadingAlert = true
        this.$axios.Post(this.$api.cfExtensionModify, this.form).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.closeAlert();
          this.getData()
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //关闭
      closeAlert() {
        this.formType = false;
        this.dialogVisible = false;
        this.$refs["form"].resetFields();
        this.form.id = "";
        this.loadingAlert = false;
        // this.getNotArchivesHouse()
      },
    }
  }
</script>

<style scoped lang="less">
  /deep/ .el-table__row .el-radio__label {
    display: none;
  }
</style>
